import React, { useEffect, useState } from "react";
import { PunchingButton } from "../../components/punchinButton";
import { TakeSnap } from "./TakeSnap";
import toast from "react-hot-toast";
import usePut from "../../hooks/put/usePut";
import { useNavigate } from "react-router-dom";
import { MdOutlineFitScreen } from "react-icons/md";
import ScreenCapture from "./ScreenCapture";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const MarkWFHAttendanceForm = () => {
  const [isLocationDenined, setIsLocationDenined] = useState(0)
  const navigate = useNavigate();
  const [zoom, setZoom] = useState(16)
  const [coordinates, setCoordinates] = useState({
    center: {
      lat: 0,
      lng: 0,
    },
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCYBAbuZ0pN5BkdsJDJ__-3ysfkl5QjdfM", // HRMS KEY
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { coords } = position
        const data = {
          lat: coords?.latitude,
          lng: coords?.longitude
        }
        setZoom(16)
        setCoordinates({ center: data })
      },
      (error) => {
        if (error.PERMISSION_DENIED) {
          setIsLocationDenined(error.PERMISSION_DENIED)
        } else {

        }
        // console.log(error.PERMISSION_DENIED);
      }
    );
  }, [])
  const markerOnLoad = (marker) => {
    // console.log('marker: ', marker)
  }
  const { handleAuth } = usePut({
    url: "user_check_in",
    onSuccess: (res) => {
      toast.success("Attendance added successfully");
      setTimeout(() => {
        navigate("/");
      }, [2000]);
    },
    onError: (err) => {
      const { status, data } = err?.response;
      if (status >= 400 || status <= 499) {
        toast.error(data.message);
      } else {
        toast.error("Internal server error");
      }
    },
  });
  const [map, setMap] = React.useState(null)
  const handleSubmitPunch = () => {
    // console.log(uploadedImage, screenCapture);

    if (!uploadedImage?.image_id) {
      toast.error("Please add picture of yourself");
      return;
    }
    if (!screenCapture?.image_id) {
      toast.error("Please add picture of work screen");
      return;
    }
    let data = {
      image_id: uploadedImage?.image_id,
      screen_image_id: screenCapture?.image_id,
      lat: coordinates?.center?.lat,
      long: coordinates?.center?.lng,
    };
    console.log(data);
    handleAuth.mutate(data);
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(coordinates.center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  const [uploadedImage, setUploadedImage] = useState({});
  const [screenCapture, setScreenCapture] = useState(null);
  const mapStyles = {
    height: "30vh",
    width: "100%"
  };

  return (
    <div className="px-5">
      <h1 className=" text-xl font-semibold pb-6">
        Marking Attendance for Work from home{" "}
      </h1>
      <ul>
        <li>
          <small>
            <b>Step 1:</b> If you are working from home today, please mark your
            attendance by clicking on the button below.
          </small>
        </li>
        <li>
          <small>
            <b>Step 2:</b> Take a screen capture.
          </small>
        </li>
        <li>
          <small>
            <b>Step 3:</b> Take a Snap of your face.
          </small>
        </li>
      </ul>

      {isLocationDenined ?
        <>
          <p className=' text-center text-red-700 mt-4 font-semibold'>Please provide access to your location.</p>
          <p className=' text-xs text-center text-gray-600'>
            Some browser extensions or settings may block popup windows, including permission prompts. Check your browser settings or disable any popup blockers temporarily to see if it resolves the issue.
          </p>
        </> : null
      }

      {!isLocationDenined &&
        <>
          <div style={{ height: '30vh', width: '100%' }}>
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={mapStyles}
                center={coordinates.center}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                <Marker visible={true} position={coordinates.center} onLoad={markerOnLoad} />
              </GoogleMap>
            ) : <></>}
          </div>
        </>
      }

      <div className="grid grid-cols-1 md:grid-cols-4 space-y-4 md:space-y-0 md:space-x-4 mt-10 md:mt-20">
        <div>
          <ScreenCapture setScreenCapture={setScreenCapture} />
        </div>
        <div>
          <TakeSnap setUploadedImage={setUploadedImage} />
        </div>
      </div>


      <div className=" my-5 text-center">
        <button
          onClick={handleSubmitPunch}
          className=" px-4 py-3 rounded-md bg-[color:var(--color1)] text-white"
        >
          Mark Attendance
        </button>
      </div>
    </div>
  );
};

export default MarkWFHAttendanceForm;
